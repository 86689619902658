import React from 'react';
import ReactDOM from 'react-dom';
import App from './client/App';
import { Provider } from 'react-redux';
import store from './redux/appState';
import './client/App.css'
import 'antd-css-utilities/utility.min.css';
import { GoogleOAuthProvider } from '@react-oauth/google';

const GOOGLE_CLIENT_ID = '50589316056-d934hfahhsrd8r3bd5ardv1unj5frrre.apps.googleusercontent.com';
console.log(GOOGLE_CLIENT_ID);
ReactDOM.render(
  <Provider store={store}>
    <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
    <App />
    </GoogleOAuthProvider>
  </Provider>,
  document.getElementById('root')
);
